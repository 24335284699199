exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-membership-form-index-js": () => import("./../../../src/pages/membership-form/index.js" /* webpackChunkName: "component---src-pages-membership-form-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-our-school-index-js": () => import("./../../../src/pages/our-school/index.js" /* webpackChunkName: "component---src-pages-our-school-index-js" */),
  "component---src-pages-pages-index-js": () => import("./../../../src/pages/pages/index.js" /* webpackChunkName: "component---src-pages-pages-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-custom-page-post-js": () => import("./../../../src/templates/custom-page-post.js" /* webpackChunkName: "component---src-templates-custom-page-post-js" */),
  "component---src-templates-events-post-js": () => import("./../../../src/templates/events-post.js" /* webpackChunkName: "component---src-templates-events-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-our-principals-page-js": () => import("./../../../src/templates/our-principals-page.js" /* webpackChunkName: "component---src-templates-our-principals-page-js" */),
  "component---src-templates-our-school-post-js": () => import("./../../../src/templates/our-school-post.js" /* webpackChunkName: "component---src-templates-our-school-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

